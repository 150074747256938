<template>
  <div class="w-100">
    <div class="d-flex justify-content-between align-items-center p-2">
      <div class="pbz-font subtitle-sm-medium mr-2" style="color: #1a1a1a">
        Inbound
      </div>
      <div class="ml-auto d-flex">
        <a-button
          size="large"
          class="inbound__button d-flex align-items-center h-48px"
          @click="openModal"
        >
          <PlusIcon class="mr-2" />
          Create Inbound
        </a-button>
      </div>
    </div>

    <a-row :gutter="24" class="mt-4">
      <a-col :span="12">
        <div class="d-flex align-items-center">
          <a-input placeholder="Cari Produk" class="h-48px" />
          <a-button class="h-48px search__button d-flex align-items-center">
            Search <SearchIcon class="ml-2" />
          </a-button>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="col-md-3 pr-0 d-flex align-items-center ml-auto">
          <a-select
            class="w-100 ml-auto text-capitalize select-antd-default"
            size="large"
            :value="sortBy"
            placeholder="Sort By"
          >
            <a-select-option
              v-for="sort in ['alphabetical', 'numeric']"
              :key="sort"
              class="text-capitalize"
              :value="sort"
            >
              {{ sort }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
    </a-row>

    <InboundList :permission="permission" class="mt-3 mb-4" />

    <Pagination
      class="mt-3 text-right"
      :total="50"
      :page-size="10"
      :total-length-per-page="10"
      :default-page="1"
    />

    <a-modal
      v-model="visibleModal"
      :width="820"
      title="Create Inbound"
      :footer="false"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <div>
            <div class="pbz-font style__label">
              Kode Ref
            </div>
            <a-input placeholder="Masukan Kode Ref" class="mt-2 h-48px" />
          </div>
        </a-col>
      </a-row>

      <hr />

      <a-row :gutter="24" class="d-flex align-items-end">
        <a-col :span="22">
          <a-row :gutter="24">
            <a-col :span="6">
              <div>
                <div class="pbz-font style__label">
                  Produk
                </div>
                <a-input
                  placeholder="SKU / Product Name"
                  class="mt-2 h-48px"
                />
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                <div class="pbz-font style__label">
                  QTY Saat Ini
                </div>
                <a-input
                  default-value="0"
                  disabled
                  class="mt-2 h-48px"
                />
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                <div class="pbz-font style__label">
                  QTY Yang Masuk
                </div>
                <a-input
                  placeholder="Masukan Jumlah"
                  class="mt-2 h-48px"
                />
              </div>
            </a-col>
            <a-col :span="6">
              <div>
                <div class="pbz-font style__label">
                  Remark
                </div>
                <a-input
                  placeholder="Masukan Remark"
                  class="mt-2 h-48px"
                />
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="2">
          <a-button
            class="d-flex align-items-center h-48px"
            type="primary"
          >
            <PlusIcon />
          </a-button>
        </a-col>
      </a-row>

      <div class="d-flex mt-5" style="gap: 1rem">
        <a-button block size="large" type="link">
          Batal
        </a-button>
        <a-button block size="large" type="primary">
          Tambah Stok
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import IconSearch from '@/components/Icons/Search.vue'
import Pagination from '@/components/Pagination'
import InboundList from '@/components/Inventory/Inbound/InboundList.vue'
import PlusIcon from '@/components/Icons/PlusIcon2.vue'
import SearchIcon from '@/components/Icons/Search.vue'

export default {
  components: {
    IconSearch,
    Pagination,
    InboundList,
    PlusIcon,
    SearchIcon,
  },
  data() {
    return {
      visibleModal: false,
      total_row: 0,
    }
  },
  computed: {
    permission() {
      return this.$store.getters['user/can']('product-master')
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length) {
  //      this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
  methods: {
    openModal() {
      this.visibleModal = true
    },
    closeModal() {
      this.visibleModal = false
    },
  },
}
</script>

<style lang="scss">
.product {
  &__search-bar {
    position: relative;
    width: 534px;
    height: 44px;

    input[type='text'] {
      border: 1px solid #999;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 8px;
      box-sizing: border-box;
      transition: 0.3s;
    }

    input[type='text']:focus {
      border-color: rgb(5, 89, 203);
      box-shadow: 0 0 8px 0 rgb(5, 89, 203);
      color: #999;
    }

    input[type='text'] {
      padding-left: 40px;
    }

    input::placeholder {
      color: #999 !important;
      font-family: 'Poppins', sans-serif !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }

    .icon-form {
      position: absolute;
      left: 7px;
      top: 10px;
      color: #999;
      transition: 0.3s;
    }
  }

  &__button-filter {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 8px;
    margin-top: -4px;
    width: 98px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    cursor: pointer;
  }

  &__button {
    background-color: #0559cb !important;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__list {
    width: 100% !important;
    border-radius: 5px !important;
    border: 1px solid #ccc !important;
    margin-left: 0 !important;

    &.head {
      color: #999;
      height: 38px !important;
    }

    &.item {
      color: #1a1a1a;
      // height: 60px !important;
    }
  }
}

.style__label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #212529;
}

.search__button {
  color: #fff !important;
  background:#2196F3 !important;
}

.inbound__button {
  color: #fff !important;
  background:#FF0854 !important;
}
</style>
