<script>
import { defineComponent } from 'vue'
import Checkbox from '@/components/Input/Checkbox.vue'

export default defineComponent({
  components: { Checkbox },
  props: ['permission'],
})
</script>

<template>
  <div class="product-list--wrapper">
    <div class="product-list--header">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mt-1">
          <Checkbox />
        </div>
        <div class="ml-2">
          Produk
        </div>
      </div>
      <div>Nomor Produk</div>
      <div>QTY</div>
      <div>Satuan</div>
      <div>Catatan</div>
      <div />
    </div>

    <div class="product-list--body">
      <div v-for="(list, index) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :key="index" class="product-list--item mt-1">
        <div>
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <Checkbox
                class="mb-0"
              />
            </div>
            <div class="light-list-item">
              <div class="bold-list-item">
                Product Title
              </div>
              SKU: KSP-012-0001
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="light-list-item">
            <div class="bold-list-item">
              KSP/2381859505
            </div>
            02 June 2022 - PCA/7199
          </div>
        </div>
        <div>
          1000
        </div>
        <div>
          PCS
        </div>
        <div>
          Penyesuaian
        </div>
        <div>
          <a-dropdown
            v-if="permission.includes('READ') || permission.includes('WRITE')"
            :overlay-style="{ width: '150px' }"
          >
            <a @click.prevent="">
              <a-icon type="more" />
            </a>

            <template #overlay>
              <a-menu>
                <a-menu-item
                  class="py-2"
                >
                  <a-icon type="delete" />
                  Hapus
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-list--wrapper {
  overflow-x: auto;

  .product-list {
    &--header,
    &--item {
      min-width: 1050px;
      display: flex;
      height: 60px;
      text-align: left;
      align-items: center;
      font-size: 0.925rem;
      //   margin-bottom: .5rem;
      background: #fff;

      > div {
        padding: 10px 15px;

        &:nth-child(1) {
          min-width: 320px;
        }

        &:nth-child(2) {
          min-width: 280px;
        }

        &:nth-child(3) {
          min-width: 100px;
        }

        &:nth-child(4) {
          min-width: 100px;
        }

        &:nth-child(5) {
          min-width: 250px;
        }

        &:last-child {
          min-width: 50px !important;
        }
      }
    }

    &--header {
      background-color: #e7ecef;
      color: #666;
      height: 56px;
      font-size: 90%;
      align-items: center;
      // margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}

.product-status {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #999999;
  font-size: 85%;
  color: #fff;
  min-width: 100px;

  &.active {
    background: #4d4d4d;
  }

  &.non_active {
    background: var(--danger);
  }
}

.bold-list-item {
  font-size: 14px;
  font-weight: 700;
  color: #495057;;
}

.light-list-item {
  font-size: 12px;
  font-weight: 400;
  color: var(--gray);
  margin-top: -0.25rem;
}
</style>
